// Import Packages
import * as React from "react"
import { Link } from "gatsby"

// Import Templates
import { PageTitle, Header, Footer} from "../templates/Components"

// Import Assets
import "../css/style.css"
import Landscape from "../images/landscape.png"

// markup
const IndexPage = ({location, props}) => {
  return (
    <div>
      <PageTitle pageTitle=""/>
      <Header/>
      <div id="contents">
        <div className="highlight">
          <div className="clearfix">
            {/* <div className="testimonial">
              <h2>Testimonials</h2>
              <p>
                &ldquo;Aenean ullamcorper purus vitae nisl tristique sollicitudin. Quisque vestibulum, erat ornare.&rdquo;
              </p>
              <span>-John Doe and Jane Doe-</span>
            </div> */}
            <div className="home-image">
              <div className="box">
                <img src={Landscape} alt="Dominic Rizzi in front of the Tucson Skyline and the Santa Catalina Mountains" height="233" width="300"/>
              </div>
            </div>
            <h1>The Law Office of Dominic Rizzi</h1>
            <p>
              Committed to excellence, committed to you, the Law Office of Dominic Rizzi is here to assist you with your legal needs. Contact us today to see what we can do for you.
            </p>
            <Link to="/contact/" className="more">Contact us</Link>	
          </div>
        </div>
        <div className="featured">
          <h2>What We Offer</h2>
          <ul className="clearfix">
            <li>
              {/* <div className="frame1">
                <div className="box">
                  <img src="images/meeting.jpg" alt="Img" height="130" width="197">
                </div>
              </div> */}
              <p>
                <b>Experienced Representation</b> When you retain the Law Office of Dominic Rizzi for legal representation, you are retaining a former prosecutor with extensive trial experience.
              </p>
              <Link to="/about/" className="more">Read More</Link>
            </li>
            <li>
              {/* <div className="frame1">
                <div className="box">
                  
                </div>
              </div> */}
              <p>
                <b>Dedicated To You</b> Our Office provides you an advocate who will go above and beyond for you.
                <br/>
                <br/>
                <br/>
              </p>
              <Link to="/practices/" className="more">Read More</Link>
            </li>
            <li>
              {/* <div className="frame1">
                <div className="box">
                  <img src="images/smile.jpg" alt="Img" height="130" width="197">
                </div>
              </div> */}
              <p>
                <b>Se Habla Español</b> We provide full service in English and Spanish. Ofrecemos servicios completos en inglés y español.
                <br/>
                <br/>
              </p>
              <Link to="/contact/" className="more">Llámenos hoy</Link>
            </li>
            {/* <li>
              <div className="frame1">
                <div className="box">
                  <img src="images/family-small.jpg" alt="Img" height="130" width="197">
                </div>
              </div>
              <p>
                <b>get in touch with us</b> And we love the challenge of doing something diferent and something special.
              </p>
              <Link to="index" className="more">Read More</Link>
            </li> */}
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default IndexPage